import React from "react"
import styled from "styled-components"
import Heading from "@elements/heading"
import Grid from "@components/elements/grid"
import Image from "@components/image"
import { graphql, useStaticQuery } from "gatsby"
import Flex from "@components/elements/flex"

const IconWrapper = styled(Flex)`
  aspect-ratio: 1 / 1;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 0.5px 2.5px #00000029, 0 1px 8px #00000012;
  height: 80px;
  width: 80px;
  position: relative;
  padding: 10px;
`


const Perks = () => {
  const { perks } = useStaticQuery(graphql`
    query TeamPerks {
      perks: allPerks(filter: { status: { eq: "published" } }) {
        edges {
          node {
            benefit
            icon {
              cdn
              id
              placeholder
              title
            }
          }
        }
      }
    }
  `)

  return (
    <Grid columns="repeat(auto-fit, minmax(260px, 1fr))" gap="4rem 2rem">
      {perks.edges.map(({ node: perk }, i) => {
        return (
          <Flex alignItems="center" gap="1rem" key={`perk-${i}`}>
            <IconWrapper>
              <Image 
                objectFit="scale-down"
                file={perk.icon}
              />
            </IconWrapper>
            <Heading center as="h5">{perk.benefit}</Heading>
          </Flex>
        )
      })}
    </Grid>
  )
}

export default Perks
